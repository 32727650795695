export default {
    init() {
        // JavaScript to be fired on all pages

        /* eslint-disable */
        
        $(document).ready(function() {
            $('.builder').each(function (index) {
                $(this).prepend('<b class="builder__scroll"></b>');

                let $sectie = 'sectie'+index;

                $(this).find('.builder__scroll').attr('id', $sectie);

                if ($('body').hasClass('logged-in')) {
                    $(this).find('.builder__scroll').text($sectie)
                }
            });

            $('.nav-primary a[href*="#"]:not([href="#"]), .btn-scroll').on('click',function(e) {
                e.preventDefault();

                var offset;

                if ($('body').hasClass('admin-bar')) {
                    offset = 32;
                } else {
                    offset = 0;
                }

                var target = this.hash;

                if ($(this).data('offset') != undefined) offset = $(this).data('offset');
                $('html, body').stop().animate({
                    'scrollTop': $(target).offset().top - offset,
                }, 800, 'swing', function() {
                    // window.location.hash = target;
                });
            });
        });
        
        /* eslint-enable */

        // // Jquery-match-height
        // $(function() {
        //     $('.example').matchHeight();
        // });

        // // Slick slider example
        // $('.slick__slider').slick({
        //     dots: true,
        //     infinite: true,
        //     speed: 300,
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        //     responsive: [
        //         {
        //             breakpoint: 1024,
        //             settings: {

        //             },
        //         },
        //     ],
        // });

        // $(document).ready(function() {
        //     $(".fancybox").fancybox();
        // });

        
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
